var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',[_vm._v("ຂໍ້ມູນການແພ່ລະບາດຂອງໂຣກຕິດເຊື້ອໄວຣັສໂຄໂຣນາສາຍພັນໃໝ່ 2019")]),_c('p',[_vm._v(" ອ້າງອີງຂໍ້ມູນຈາກ "),_c('a',{attrs:{"href":"https://disease.sh","rel":"noopener noreferer","target":"_blank"}},[_vm._v("disease.sh")])])]),_c('v-divider'),_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"cols":_vm.cols}},[_c('summary-card',{attrs:{"title":"ກວດພົບ","color":"orange","icon":"fas fa-virus","dark":true,"overall":_vm.overall.cases,"laos":_vm.laos.cases}})],1),_c('v-col',{attrs:{"cols":_vm.cols}},[_c('summary-card',{attrs:{"title":"ເສຍຊີວິດ","color":"red","icon":"fas fa-skull-crossbones","dark":true,"overall":_vm.overall.deaths,"laos":_vm.laos.deaths}})],1),_c('v-col',{attrs:{"cols":_vm.cols}},[_c('summary-card',{attrs:{"title":"ປິ່ນປົວແລ້ວ","color":"green","icon":"fas fa-smile","dark":true,"overall":_vm.overall.recovered,"laos":_vm.laos.recovered}})],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"my-4",attrs:{"headers":_vm.tableHeaders,"items":_vm.allCountries,"items-per-page":25,"search":_vm.searchText,"sort-by":"cases","sort-desc":true,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h3',[_vm._v("ຕາຕະລາງຂໍ້ມູນການແພ່ລະບາດຂອງພະຍາດໂຄວິດ-19")]),_c('v-text-field',{attrs:{"label":"ຄົ້ນຫາປະເທດ (ພາສາອັງກິດເທົ່ານັ້ນ)"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})]},proxy:true},{key:"item.cases",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numFormat")(value)))])]}},{key:"item.todayCases",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numFormat")(value)))])]}},{key:"item.deaths",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numFormat")(value)))])]}},{key:"item.todayDeaths",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numFormat")(value)))])]}},{key:"item.active",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numFormat")(value)))])]}},{key:"item.recovered",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numFormat")(value)))])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(_vm._s(("ລາຍການທີ " + (props.pageStart) + "-" + (props.pageStop) + " ຈາກທັງໝົດ " + (props.itemsLength) + " ລາຍການ")))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }